import { Helmet } from "react-helmet-async";

const bgBanner = require("../../assets/images/ProductosBannerBg.png");
const banner = require("../../assets/images/UniSalud_Banner.png");

const contenidoUniSalud = [
  {
    title: "Descripción",
    text: "Tarjeta MAESTRO diseñada para brindar a los beneficiarios provisiones de gastos médicos, farmacéuticos y odontológicos, otorgados como incentivo salarial, mejorando así su calidad de vida.",
  },
];

const benificiosUS = [
  {
    title: "Beneficios",
    lists: [
      {
        listText:
          "Este producto elimina a la empresa los cúmulos de cheques por firmar y riesgos de manejo de efectivo, simplificando la gestión administrativa.",
      },
      {
        listText:
          "Manera práctica, fácil , sencilla y segura de cargar los montos correspondientes de cada empleado, eliminando el costo de cheques.",
      },
      {
        listText:
          "Tarjeta afiliada a la red MAESTRO, lo cual garantiza una plataforma con los mayores estándares de seguridad y aceptación.",
      },
      {
        listText:
          "Manera práctica, fácil y sencilla de otorgar beneficio social de carácter no remunerativo, enmarcado en la Ley en los artículos 105, 131 Y 133 de LOTTT.",
      },
      {
        listText:
          "Genera al empleado mayor poder adquisitivo y motivación laboral, incrementando el presupuesto familiar y logra un equilibrio en las remuneraciones.",
      },
      {
        listText:
          "Atención al Cliente completamente personalizada a través de nuestra central telefónica y página web.",
      },
      {
        listText:
          "Grupo Único cuenta con atención integral de todos los servicios financieros y la asesoría que requiere la empresa.",
      },
    ],
  },
];

const UniSalud = () => {
  return (
    <div>
      <Helmet>
        <title>UniSalud -Uniticket</title>
      </Helmet>
      <div
        className="relative overflow-hidden bg-no-repeat bg-cover max-w-auto h-auto mb-16"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${bgBanner})`,
          height: "440px",
        }}
      >
        <div className="flex flex-col items-center justify-items-center mt-16 mb-16">
          <img src={banner} className="max-w-full h-auto text-center" alt="" />
          <div className="flex flex-row mt-8 ">
            <p className="text-orange-500 font-bold text-3xl">Uni</p>
            <p className="text-white font-bold text-3xl">Salud</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col mb-10">
        {contenidoUniSalud.map(({ title, text }, i) => {
          return (
            <div className="flex flex-col lg:flex-row items-center ">
              <div className="max-w-auto mx-8 md:ml-28 basic-1/2">
                <p className="text-orange-500 text-3xl font-bold my-10">
                  {title}
                </p>
                <p className="text-gray-500 text-base text-justify my-10">
                  {text}
                </p>
              </div>
            </div>
          );
        })}

        {benificiosUS.map(({ title, lists }, i) => {
          return (
            <div className="max-w-auto mx-8 md:ml-28 basic-1/2">
              <p className="text-orange-500 text-3xl font-bold my-10">
                {title}
              </p>
              <div className="flex flex-col justify-center mb-2">
                {lists.map(({ listText }, i) => {
                  return (
                    <div className="">
                      <ul className="text-gray-500 text-base text-justify my-2 ">
                        <li className="">{listText}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UniSalud;
