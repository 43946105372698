import { Helmet } from "react-helmet-async";
import { routes } from "../routes/routes";

const banner = require("../assets/images/PreguntasFrecuentas_Banner.jpg");

const PreguntasFrecuentes = () => {
  const acordeonValores = [
    {
      title: "¿Cómo puedo cotizar con ustedes?",
      text: (
        <>
          Le ofrecemos todo nuestro portafolio de servicios postventa y le
          asignamos una ejecutiva de ventas en tiempo real. Sólo debe escribir a
          la siguiente dirección electrónica:{" "}
          <a
            className="text-orange-300"
            href="mailto:cotizauniticket@grupounico.com.ve"
          >
            cotizauniticket@grupounico.com.ve
          </a>{" "}
          y obtendrá un certificado electrónico donde debe ingresar sus datos
          personales y en un lapso de 2 horas obtendrá el resultado de su
          solicitud.
          <br />
          <br />
          Gracias por preferirnos…
        </>
      ),
    },
    {
      title: "¿Dónde es aceptada mi tarjeta de alimentación?",
      text: "La tarjeta Uniticket puede ser usada en cualquier comercio con puntos de venta afiliados a la red Maestro, con más de 320.000 puntos a escala nacional.",
    },
    {
      title: "¿Dónde puedo consultar mi saldo?",
      text: (
        <>
          Hemos logrado que nuestros productos se puedan consultar a través de
          nuestro sitio web{" "}
          <a
            className="text-orange-300"
            href={routes.home}
            target={"_blank"}
            rel="noreferrer"
          >
            www.uniticket.com.ve
          </a>{" "}
          <br />
          <br />
          Sólo debe introducir su número de tarjeta y su cédula de identidad y
          luego de un click obtendrá el resultado de su saldo, estados de
          cuenta, mensajes de voz y envíos de SMS. De igual manera, puede
          consultar su saldo a través de nuestras diferentes pasarelas de
          comunicación como lo son los cajeros automáticos, en donde puede
          consultar su saldo y realizar un cambio de clave; también puede
          comunicarse al teléfono master 8210000.
        </>
      ),
    },
    {
      title: "¿Qué tipo de Red es la Tarjeta Electrónica de Alimentación?",
      text: "Es una red abierta afiliada a Master Card Internacional y es respaldada por la marca Maestro en el territorio nacional, contando con más de 320.000 puntos de venta.",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Preguntas Frecuentes -Uniticket</title>
      </Helmet>
      <div
        className="bg-cover h-44 w-full lg:h-[500px]"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${banner})`,
        }}
      ></div>

      <h3 className="text-orange-500 text-3xl text-bold text-center mb-10 my-10">
        Preguntas Frecuentes
      </h3>
      <div className="flex justify-center mx-8 lg:mx-64 mb-16">
        <div
          className="accordion accordion-flush w-full "
          id="accordionFlushExample"
        >
          {acordeonValores.map(({ title, text }, i) => {
            const headingOne = `flush-headingOne${i}`;
            const HeadingOne = `#flush-headingOne${i}`;
            const collapseOne = `flush-collapseOne${i}`;
            const CollapseOne = `#flush-collapseOne${i}`;

            return (
              <div className="accordion-item border-solid border-3 rounded-none bg-white border border-gray-300 text-gray-600">
                <h2
                  className="accordion-header mb-0 text-gray-600 "
                  id={headingOne}
                >
                  <button
                    className="accordion-button
                        relative
                        flex
                        items-center
                        w-full
                        py-4
                        px-5
                        text-base text-gray-600 text-left
                        bg-white
                        border-0
                        rounded-none
                        transition
                        focus:outline-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={CollapseOne}
                    aria-expanded="false"
                    aria-controls={collapseOne}
                  >
                    {title}
                  </button>
                </h2>
                <div
                  id={collapseOne}
                  className="accordion-collapse border-0 collapse hide"
                  aria-labelledby={headingOne}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body py-4 px-5 text-gray-600">
                    {text}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
