import NavLink from "./NavLink";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { routes } from "../../routes/routes";
import { Transition } from "@headlessui/react";
import { useState } from "react";

const img_logo = require("../../assets/images/logo.png");

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const links = [
    { text: "inicio", to: routes.home },
    { text: "nosotros", to: routes.nosotros },
    {
      text: "Productos",
      to: routes.productos,
      children: [
        { text: "Tarjeta Multibolsillo en Dolares", to: routes.home },
        { text: "UniRecarga", to: routes.productosUniRecarga },
        { text: "UniEfectiva", to: routes.productosUniEfectiva },
        { text: "UniMonedero", to: routes.productosUniMonedero },
        {
          text: "Uniticket Alimentacion",
          to: routes.productosUniticketAlimentacion,
        },
        { text: "UniFamilia", to: routes.productosUniFamilia },
        { text: "UniSalud", to: routes.productosUniSalud },
        { text: "UniReembolso", to: routes.productosUniReembolsos },
      ],
    },
    { text: "Servicios", to: routes.servicios },
    { text: "Preguntas frecuentes", to: routes.preguntasFrecuentes },
    { text: "Contacto", to: routes.contacto },
  ];

  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="container flex flex-wrap items-center mx-auto">
              <a href={routes.home} className="flex items-center">
                <img src={img_logo} className="mr-3 h-8 sm:h-9" />
              </a>
            </div>
            <div className="hidden lg:block">
              <div className=" flex items-center  space-x-4">
                <ul className="flex flex-col mt-4 md:flex-row md:space-x-16 md:mt-0 md:text-sm md:font-medium ml-8">
                  {links.map(({ text, to, children }) => (
                    <NavLink to={to} subLinks={children}>
                      {text}
                    </NavLink>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-800"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="lg:hidden " id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 lg:px-3">
              <ul className="flex flex-col mt-4 lg:flex-row lg:space-x-16 lg:mt-0 lg:text-sm lg:font-medium">
                {links.map(({ text, to, children }) => (
                  <NavLink to={to} subLinks={children}>
                    {text}
                    <br />
                  </NavLink>
                ))}
              </ul>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

export default Navbar;
