import Contacto from "../pages/Contacto";
import Home from "../pages/Home";
import Nosotros from "../pages/Nosotros";
import PreguntasFrecuentes from "../pages/PreguntasFrecuentes";
import Productos from "../pages/Productos";
import UniEfectiva from "../pages/Productos/UniEfectiva";
import UniFamilia from "../pages/Productos/UniFamilia";
import UniMonedero from "../pages/Productos/UniMonedero";
import UniRecarga from "../pages/Productos/UniRecarga";
import UniReembolsos from "../pages/Productos/UniReembolsos";
import UniSalud from "../pages/Productos/UniSalud";
import UniticketAlimentacion from "../pages/Productos/UniticketAlimentacion";
import Servicios from "../pages/Servicios";

interface DirRoutes {
  path: string;
  element: JSX.Element;
}

export const routes = {
  home: "/",
  nosotros: "/nosotros",
  productos: "/productos",
  productosUniRecarga: "/productos/uni-recarga",
  productosUniEfectiva: "/productos/uni-efectiva",
  productosUniMonedero: "/productos/uni-monedero",
  productosUniticketAlimentacion: "/productos/uniticket-alimentacion",
  productosUniFamilia: "/productos/unifamilia",
  productosUniSalud: "/productos/unisalud",
  productosUniReembolsos: "/productos/unireembolsos",
  servicios: "/servicios",
  preguntasFrecuentes: "/preguntas-frecuentes",
  contacto: "/contacto",
};

export const publicDir: DirRoutes[] = [
  { path: routes.home, element: <Home /> },
  { path: routes.nosotros, element: <Nosotros /> },
  { path: routes.productos, element: <Productos /> },
  { path: routes.productosUniRecarga, element: <UniRecarga /> },
  { path: routes.productosUniEfectiva, element: <UniEfectiva /> },
  { path: routes.productosUniMonedero, element: <UniMonedero /> },
  {
    path: routes.productosUniticketAlimentacion,
    element: <UniticketAlimentacion />,
  },
  { path: routes.productosUniFamilia, element: <UniFamilia /> },
  { path: routes.productosUniSalud, element: <UniSalud /> },
  { path: routes.productosUniReembolsos, element: <UniReembolsos /> },
  { path: routes.servicios, element: <Servicios /> },
  { path: routes.preguntasFrecuentes, element: <PreguntasFrecuentes /> },
  { path: routes.contacto, element: <Contacto /> },
];

export const privateDir: DirRoutes[] = [
  //{ path: routes.home, element: <HomePage /> },
];
