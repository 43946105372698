import { Helmet } from "react-helmet-async";
import { routes } from "../routes/routes";

const bgBanner = require("../assets/images/Servicios_Banner.png");
const pasosTarjeta = require("../assets/images/Servicios_PasosTarjeta.jpg");

const Servicios = () => {
  const acordeonServicios = [
    {
      title: "Facturación en Línea",
      text: "Tenemos la flexibilidad de realizar las entregas, bien sea en las sedes de las empresas-cliente y/o donde éste tenga presencia a escala nacional, a través de nuestro servicio online, descargando un link donde se puede visualizar la copia fiel y exacta de la factura original. De esta manera se reducen los plazos de entrega presenciales y se agiliza la cancelación de los servicios a través de transferencias en forma mensual o una vez solicitado el pedido. Los números o tiempo de entrega y la recarga del dinero se realizará al beneficiario según niveles de servicio establecidos en la negociación.",
    },
    {
      title: "Consulta de Saldo",
      text: (
        <>
          Hemos logrado que nuestros productos se puedan consultar a través de
          nuestro sitio web{" "}
          <a className="text-orange-300" href={routes.home} target={"_blank"}>
            www.uniticket.com.ve
          </a>{" "}
          <br />
          <br />
          Sólo debe introducir su número de tarjeta y su cédula de identidad y
          luego de un click obtendrá el resultado de su saldo, estados de
          cuenta, mensajes de voz y envíos de SMS.
          <br />
          <br />
          De igual manera, puede consultar su saldo a través de nuestras
          diferentes pasarelas de comunicación como lo son los cajeros
          automáticos en donde puede consultar su saldo y realizar un cambio de
          clave; también puede comunicarse al teléfono master 8210000.
        </>
      ),
    },
    {
      title: "Atención al Cliente Personalizada",
      text: "Las tarjetas electrónicas Uniticket pueden ser utilizadas en cualquier punto de venta en la red Maestro. Ofrecemos a todos nuestros clientes servicios postventa con la asignación de una ejecutiva de ventas para atender todos los requerimientos. Le ofrecemos seguridad, respaldo tecnológico, software o programas para el acceso a servicios online, tales como: carga de pedidos, estatus de pedidos, seguimiento de pedidos, consulta de información de los beneficiarios y de los comercios afiliados.",
    },
    {
      title: "Cotizar en Línea",
      text: "Muy pronto estará disponible…",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Servicios -Uniticket</title>
      </Helmet>
      <div
        className="bg-cover h-44 w-full lg:h-[500px]"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${bgBanner})`,
        }}
      ></div>

      <h3 className="text-orange-500 text-3xl text-bold text-center mb-10 mt-10">
        Servicios
      </h3>
      <div className="flex justify-center mx-8 lg:mx-64 mb-16">
        <div
          className="accordion accordion-flush w-full text-gray-800"
          id="accordionFlushExample"
        >
          {acordeonServicios.map(({ title, text }, i) => {
            const headingOne = `flush-headingOne${i}`;
            const HeadingOne = `#flush-headingOne${i}`;
            const collapseOne = `flush-collapseOne${i}`;
            const CollapseOne = `#flush-collapseOne${i}`;

            return (
              <div className="accordion-item border-solid border-3 rounded-none bg-white border border-gray-300 text-gray-600">
                <h2
                  className="accordion-header mb-0 text-gray-600 "
                  id={headingOne}
                >
                  <button
                    className="accordion-button
                        relative
                        flex
                        items-center
                        w-full
                        py-4
                        px-5
                        text-base
                        text-gray-600
                        text-left
                        bg-white
                        border-0
                        rounded-none
                        transition
                        focus:outline-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={CollapseOne}
                    aria-expanded="false"
                    aria-controls={collapseOne}
                  >
                    {title}
                  </button>
                </h2>
                <div
                  id={collapseOne}
                  className="accordion-collapse border-0 collapse hide"
                  aria-labelledby={headingOne}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body py-4 px-5 text-gray-600">
                    {text}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <img src={pasosTarjeta} className="w-full p-6 lg:p-0 lg:px-44 lg:mb-10" />
    </div>
  );
};

export default Servicios;
