import { Helmet } from "react-helmet-async";

const banner = require("../assets/images/NosotrosBanner.png");
const mision = require("../assets/images/NosotrosMisionIcon.png");
const vision = require("../assets/images/NosotrosVisionIcon.png");
const valores = require("../assets/images/NosotrosValoresIcon.png");
const marcoLegal = require("../assets/images/NosotrosMarcoLegal.png");

const cuartillas = [
  {
    icon: mision,
    title: "Misión",
    text: "Ofrecer una solución viable, segura y confiable a los clientes, para el manejo de programas sociales y de sistemas de medios de pago, mediante constantes innovaciones y trabajo en equipo, para satisfacer las expectativas de los clientes, usuarios y de los comercios afiliados.",
  },
  {
    icon: vision,
    title: "Visión",
    text: "Ser reconocidos como la empresa líder en el manejo de medios de pago en el sector de beneficio social, en el ámbito nacional, mediante la implementación de sistemas de pago, desarrollando nuevas tecnologías e innovando constantemente.",
  },
];

const acordeonValores = [
  {
    title: "En Uniticket Digital las cosas se hacen bien:",
    text: (
      <>
        <p>
          <span className="text-orange-500 font-bold">C</span>on el compromiso
          de
        </p>
        <br />
        <p>
          <span className="text-orange-500 font-bold">A</span>cción
        </p>
        <br />
        <p>
          <span className="text-orange-500 font-bold">C</span>onstante
        </p>
        <br />
        <p>
          <span className="text-orange-500 font-bold">H</span>acia la
        </p>
        <br />
        <p>
          <span className="text-orange-500 font-bold">E</span>xcelencia
        </p>
      </>
    ),
  },
  {
    title: "Laboramos con sentido de responsabilidad",
    text: "En Uniticket la calidad, la productividad y el servicio al cliente son nuestra meta y tienen que ser de clase mundial ya que en el mercado actual, donde domina la innovación tecnológica, la externalización, el negocio electrónico y la competencia global, así como la excelencia operacional es cada vez más importante para las empresas.  En esta lucha, la implantación de técnicas avanzadas de fabricación es fundamental para alcanzar el éxito, pero para lograrlo es de suma importancia el talento humano, ya que lo más importante no es cómo la tecnología funcione sino cómo la gente trabaje.",
  },
  {
    title: "Lograr la máxima felicidad y satisfacción",
    text: (
      <>
        <p>
          Uniticket emplea los 12 pilares fundamentales que nos permiten lograr
          la máxima felicidad y satisfacción:
        </p>
        <br />
        <ul className="list-decimal list-inside no-underline font-bold">
          <li>Los valores fundamentales</li>
          <li>Enfoque a largo plazo</li>
          <li>Liderazgo local</li>
          <li>Comunicación continua</li>
          <li>Colaboracion</li>
          <li>Oportunidades para el desarrollo</li>
          <li>Velocidad y agilidad</li>
          <li>Motivación al logro</li>
          <li>Capitalizar el conocimiento</li>
          <li>Setntido de pertenencia</li>
          <li>Inteligencia emocional</li>
          <li>Evaluación al desempeño</li>
        </ul>
      </>
    ),
    lists: [
      {
        list: "",
      },
      {
        list: "Enfoque a largo plazo",
      },
      {
        list: "Liderazgo local",
      },
      {
        list: "Comunicación continua",
      },
      {
        list: "Colaboración",
      },
      {
        list: "Oportunidades para el desarrollo",
      },
      {
        list: "Velocidad y agilidad",
      },
      {
        list: "Motivación al logro",
      },
      {
        list: "Capitalizar el conocimiento",
      },
      {
        list: "Sentido de pertenencia",
      },
      {
        list: "Inteligencia emocional",
      },
      {
        list: "Evalución al desempeño",
      },
    ],
  },
  {
    title: "Crear productividad financiera",
    text: "En Uniticket pensamos en obtener “más por menos”; es decir, ayudamos a rentabilizar un negocio a través del mejoramiento del gasto financiero, generando mayor utilidad, mejorando los indicadores y enseñando a gestionar el portafolio financiero. En otras palabras, mejorando la relación del estado de ganancias y pérdidas de las empresas.",
  },
  {
    title: "Garantizar el crecimiento y penetración del mercado",
    text: "En Uniticket tenemos como estrategia de crecimiento empresarial aumentar la venta de nuestros productos a segmentos de mercado ya existentes, con la constante innovación de los distintos productos y servicios en el mercado de medios de pago.",
  },
];

const acordeonMarcoLegal = [
  {
    title:
      "Gaceta Oficial de la República Bolivariana de Venezuela Nº 6.269 EXTRAORDINARIO, de fecha28 de octubre 2016",
    text: "DECRETO N° 11 EN EL MARCO DEL ESTADO DE EXCEPCION Y EMERGENCIA ECONÓMICA, MEDIANTE EL CUAL SE INCREMENTA LA BASE DE CALCULO PARA EL PAGO DEL BENEFICIO DEL CESTATICKET SOCIALISTA Artículo I .Se ajusta la base de cálculo para el pago del Cestaticket Socialista para los trabajadores y las trabajadoras que presten servicios en los sectores públicos y privados, a doce Unidades Tributarias (12 U .T .) por día, a razón de treinta (3 0 ) días por mes, pudiendo percibir hasta un máximo del equivalente a trescientas sesenta Unidades Tributarias (360 U .T .) al mes, equivalente a la cantidad de SESENTA Y TRES MIL SETECIENTOS VEINTE BOLÍVARES SIN CÉNTIMOS (Bs. 63.720,00) para la techa de la publicación de este Decreto, sin perjuicio de lo dispuesto en el artículo 7o del Decreto con Rango, Valor y Fuerza de Ley del Cestaticket Socialista para los Trabajadores y Trabajadoras.",
  },
  {
    title:
      "Gaceta Oficial de la República Bolivariana de Venezuela Nº 40.773, de fecha 23 de octubre de 2015",
    text: "Es publicado el Decreto N° 2.066, mediante el cual se dicta la Ley del Cestaticket Socialista para los Trabajadores y Trabajadoras. Este Decreto tiene por objeto regular el Cestaticket Socialista, como beneficio de alimentación para proteger y defender la capacidad adquisitiva de los trabajadores y las trabajadoras en materia alimentaria, a Fin de fortalecer su salud, prevenir las enfermedades ocupacionales y propender a una mayor productividad laboral.”",
  },
  {
    title:
      "Gaceta Oficial de la República Bolivariana de Venezuela Nº 40.965, de fecha 12 de agosto de 2016",
    text: "Decreto Nº 2.430 de la misma fecha, mediante el cual se ajusta el pago del Cestaticket Socialista para los Trabajadores y Trabajadoras, en los siguientes términos: El monto diario del beneficio de alimentación otorgado en ticket, tarjeta electrónica o efectivo en los casos en que la Ley lo permite- se incrementa de 3,5 U.T. a 8 U.T. diarias, lo cual equivale a Bs. 1.416,00.El beneficio será otorgado a razón de 30 días por mes, en consecuencia, el monto mensual máximo se ajusta del equivalente a 105 U.T mensuales a 240 U.T. por mes, a saber: Bs. 42.480,00. Al mencionado Decreto se le dio vigencia a partir del 1º de agosto de 2016, lo cual significa que la carga en tarjeta o tickets correspondientes al mes de agosto, deberán ser emitidas por las cantidades antes mencionadas”",
  },
];

function Nosotros() {
  return (
    <div className="">
      <Helmet>
        <title>Nosotros -Uniticket</title>
      </Helmet>
      <div
        className="bg-cover h-44 w-full lg:h-[500px]"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${banner})`,
        }}
      ></div>

      <div className="flex lg:flex-row flex-col lg:mt-24 mt-10 justify-around">
        {cuartillas.map(({ icon, title, text }, i) => {
          return (
            <div className="">
              <div className=" bg-whit mx-4 lg:mx-16 text-center flex flex-col items-center">
                <img className="w-20 m-auto" src={icon} alt="" />

                <div className="p-6">
                  <h5 className="text-orange-500 text-2xl text-bold mb-2 ">
                    {title}
                  </h5>
                  <p className="text-gray-500  mb-4 text-justify">{text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className=" bg-white max-w-sm text-center mx-auto">
        <img className="w-20 mx-auto" src={valores} alt="" />
        <p className="text-orange-500 text-2xl text-bold mb-10 pt-6">Valores</p>
      </div>

      <div className="flex justify-center mx-8 lg:mx-64 mb-16">
        <div
          className="accordion accordion-flush w-full "
          id="accordionFlushExample"
        >
          {acordeonValores.map(({ title, text, lists }, i) => {
            const headingOne = `flush1-headingOne${i}`;
            const HeadingOne = `#flush1-headingOne${i}`;
            const collapseOne = `flush1-collapseOne${i}`;
            const CollapseOne = `#flush1-collapseOne${i}`;

            return (
              <div className="accordion-item border-solid border-3 rounded-none bg-white border border-gray-300 text-gray-600">
                <h2
                  className="accordion-header mb-0 text-gray-600 "
                  id={headingOne}
                >
                  <button
                    className="accordion-button
                        relative
                        flex
                        items-center
                        w-full
                        py-4
                        px-5
                        text-base text-gray-600 text-left
                        bg-white
                        border-0
                        rounded-none
                        transition
                        focus:outline-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={CollapseOne}
                    aria-expanded="false"
                    aria-controls={collapseOne}
                  >
                    {title}
                  </button>
                </h2>
                <div
                  id={collapseOne}
                  className="accordion-collapse border-0 collapse hide"
                  aria-labelledby={headingOne}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body py-4 px-5 text-gray-600">
                    {text}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className=" bg-white max-w-sm text-center mx-auto">
        <img className="w-20 mx-auto" src={marcoLegal} alt="" />
        <p className="text-orange-500 text-2xl text-bold mb-10 pt-6">
          MarcoLegal
        </p>
      </div>

      <div className="flex justify-center mx-8 lg:mx-64 mb-16">
        <div
          className="accordion accordion-flush w-full "
          id="accordionFlushExample2"
        >
          {acordeonMarcoLegal.map(({ title, text }, f) => {
            const headingOne = `flush-headingOne${f}`;
            const HeadingOne = `#flush-headingOne${f}`;
            const collapseOne = `flush-collapseOne${f}`;
            const CollapseOne = `#flush-collapseOne${f}`;

            return (
              <div className="accordion-item border-solid border-3 rounded-none bg-white border border-gray-300 text-gray-800">
                <h2
                  className="accordion-header mb-0 text-gray-800 "
                  id={headingOne}
                >
                  <button
                    className="accordion-button
                        relative
                        flex
                        items-center
                        w-full
                        py-4
                        px-5
                        text-base text-gray-800 text-left
                        bg-white
                        border-0
                        rounded-none
                        transition
                        focus:outline-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={CollapseOne}
                    aria-expanded="false"
                    aria-controls={collapseOne}
                  >
                    {title}
                  </button>
                </h2>
                <div
                  id={collapseOne}
                  className="accordion-collapse border-0 collapse hide"
                  aria-labelledby={headingOne}
                  data-bs-parent="#accordionFlushExample2"
                >
                  <div className="accordion-body py-4 px-5">{text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
