import { Helmet } from "react-helmet-async";

const bgBanner = require("../../assets/images/ProductosBannerBg.png");
const banner = require("../../assets/images/UniMonedero_Banner.png");
const post = require("../../assets/images/UniMonedero_Post.jpg");

const descriptionUniMonedero = [
  {
    title: "Descripción",
    text: "Tarjeta CONTACT LESS diseñado, desarrollado y programado a la medida de las necesidades de un cliente específico, tomando en cuenta que es un producto para realizar micropagos de multipropósito más eficiente para aquellas transacciones de bajo monto y alto volumen que requieran gran seguridad y velocidad.",
    img: post,
  },
];

const benificiosUM = [
  {
    title: "Beneficios",
    lists: [
      {
        listText:
          "Tarjeta innovadora CONTACT LESS que cuenta con Tecnología MV, lo cual garantiza una plataforma con los mayores estándares de seguridad y aceptación.",
      },
      {
        listText:
          "Contiene un chip electrónico que permite pagar productos y servicios deslizando la tarjeta en un lector cuyo importe exacto se acreditará del chip.",
      },
      {
        listText:
          "Vinculación de tarjeta de crédito y/o débito para recarga automatizada.",
      },
      {
        listText:
          "Mayor seguridad y control de los pequeños gastos de todos los días, eliminando problemas de cambio, falta de monedas, largas filas, congestionamiento en los puntos de pago, ganando tiempo por su rapidez y practicidad.",
      },
      {
        listText:
          "El sistema de Grupo Único posee ágiles y confiables procesos de solicitud de emisión y abono de tarjetas. Asimismo, mismo administra restricciones de uso de la tarjeta según categoría de comercios.",
      },
      {
        listText:
          "Atención al Cliente completamente personalizada a través de nuestra central telefónica y página Web.",
      },
      {
        listText:
          "Grupo Único cuenta con atención integral de todos los servicios financieros y la asesoría que requiere la empresa.",
      },
    ],
  },
];

const UniMonedero = () => {
  return (
    <div>
      <Helmet>
        <title>UniMonedero -Uniticket</title>
      </Helmet>
      <div
        className="relative overflow-hidden bg-no-repeat bg-cover max-w-auto h-auto mb-16"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${bgBanner})`,
          height: "440px",
        }}
      >
        <div className="flex flex-col items-center justify-items-center mt-16 mb-16">
          <img src={banner} className="max-w-full h-auto text-center" alt="" />
          <div className="flex flex-row mt-8 ">
            <p className="text-orange-500 font-bold text-3xl">Uni</p>
            <p className="text-white font-bold text-3xl">Monedero</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {descriptionUniMonedero.map(({ title, text, img }, i) => {
          return (
            <div className="flex flex-col lg:flex-row items-center">
              <div className="max-w-auto mx-8 md:ml-28 basic-1/2">
                <p className="text-orange-500 text-3xl font-bold my-10">
                  {title}
                </p>
                <p className="text-gray-500 text-base text-justify my-10">
                  {text}
                </p>
              </div>
              <div className=" lg:mr-40 basic-1/2 ">
                <img
                  src={img}
                  className="max-w-[100%] sm:max-w-sm px-8 h-auto"
                />
              </div>
            </div>
          );
        })}

        {benificiosUM.map(({ title, lists }, i) => {
          return (
            <div className="max-w-auto mx-8 md:mx-28 basic-1/2 mb-10">
              <p className="text-orange-500 text-3xl font-bold my-10">
                {title}
              </p>
              <div className="flex flex-col justify-center mb-2">
                {lists.map(({ listText }, i) => {
                  return (
                    <div className="">
                      <ul className="text-gray-500 text-base text-justify my-2 ">
                        <li className="">{listText}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UniMonedero;
