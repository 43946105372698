import { Helmet } from "react-helmet-async";

const bgBanner = require("../../assets/images/ProductosBannerBg.png");
const banner = require("../../assets/images/uniFamilia_Banner.png");

const contenidoUniFamilia = [
  {
    title: "Descripción",
    text: "Tarjeta MAESTRO MULTIBOLSILLO desarrollada para cubrir y satisfacer total o parcialmente las necesidades del núcleo familiar directo del beneficiario, enmarcados en la Ley. Por medio de esta tarjeta la empresa le otorga a sus trabajadores el beneficio de cancelar el pago de guarderías, le da facilidad para gestionar la compra de juguetes, le brinda el beneficio y apoyo para la compra de útiles y suministros escolares.",
  },
];

const benificiosUF = [
  {
    title: "Beneficios",
    lists: [
      {
        listText:
          "Este producto elimina a la empresa los cúmulos de cheques por firmar y riesgos de manejo de efectivo, simplificando la gestión administrativa.",
      },
      {
        listText:
          "Manera práctica, fácil , sencilla y segura de cargar los montos correspondientes a la nómina de cada empleado, eliminando el costo de cheques o sobres de pago.",
      },
      {
        listText:
          "Manera práctica, fácil y sencilla de otorgar un beneficio social de carácter no remunerativo, enmarcado en la ley en los artículos 105 y 131 de LOTTT y reglamento respectivo.",
      },
      {
        listText:
          "Tarjeta afiliada a la red MAESTRO, lo cual garantiza una plataforma con los mayores estándares de seguridad y aceptación con más de 320.000 puntos de ventas afiliados.",
      },
      {
        listText:
          "El sistema de Grupo Único posee ágiles y confiables procesos de solicitud de emisión y abono de tarjetas. Asimismo, administra restricciones de uso de la tarjeta según categoría de comercios.",
      },
      {
        listText:
          "Tarjeta PrePagada con asignación de PIN para el uso de cajeros automáticos, donde podrá retirar su efectivo. Atención al Cliente completamente personalizada a través de nuestra central telefónica y página Web.",
      },
      {
        listText:
          "Grupo Único cuenta con atención integral de todos los servicios financieros y la asesoría que requiere la empresa.",
      },
    ],
  },
];

const UniFamilia = () => {
  return (
    <div>
      <Helmet>
        <title>UniFamilia -Uniticket</title>
      </Helmet>
      <div
        className="relative overflow-hidden bg-no-repeat bg-cover max-w-auto h-auto mb-16"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${bgBanner})`,
          height: "440px",
        }}
      >
        <div className="flex flex-col items-center justify-items-center mt-16 mb-16">
          <img src={banner} className="max-w-full h-auto text-center" alt="" />
          <div className="flex flex-row mt-8 ">
            <p className="text-orange-500 font-bold text-3xl">Uni</p>
            <p className="text-white font-bold text-3xl">Familia</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {contenidoUniFamilia.map(({ title, text }, i) => {
          return (
            <div className="flex flex-col lg:flex-row items-center">
              <div className="max-w-auto mx-8 md:ml-28 basic-1/2">
                <p className="text-orange-500 text-3xl font-bold my-10">
                  {title}
                </p>
                <p className="text-gray-500 text-base text-justify my-10">
                  {text}
                </p>
              </div>
            </div>
          );
        })}

        {benificiosUF.map(({ title, lists }, i) => {
          return (
            <div className="flex flex-col lg:flex-row mb-10">
              <div className="max-w-auto mx-8 md:ml-28 basic-1/2">
                <p className="text-orange-500 text-3xl font-bold my-10">
                  {title}
                </p>
                <div className="flex flex-col lg:flex-row justify-center mb-2">
                  {lists.map(({ listText }, i) => {
                    return (
                      <div className="">
                        <ul className="text-gray-500 text-base text-justify my-2 ">
                          <li className="">{listText}</li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UniFamilia;
