import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const iconFooter = [
  {
    icon: <FaFacebookF />,
    link: "https://www.facebook.com/uniticket.digital/",
  },
  {
    icon: <FaTwitter />,
    link: "https://twitter.com/uniticketvzla?lang=es",
  },
  {
    icon: <FaInstagram />,
    link: "https://www.instagram.com/uniticketvzla/",
  },
  {
    icon: <FaYoutube />,
    link: "https://www.youtube.com/channel/UCuj02XDwnuya_2pkEUm6cWA",
  },
];

const Footer = () => {
  return (
    <div className="bg-gray-700 text-white w-full py-5 flex lg:flex-row  flex-col-reverse justify-around items-center">
      <p className="mt-4 lg:my-0">&#169; Grupo Unico C.A. J-30913093-5 </p>
      <div className="flex flex-row px-2 text-2xl space-x-4 hover">
        {iconFooter.map(({ icon, link }, i) => {
          return (
            <a
              href={link}
              className="transition delay-150 duration-300 hover:text-orange-400 font-semibold"
            >
              {icon}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
