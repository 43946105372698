import React, { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  subLinks?: {
    to?: string;
    href?: string;
    text: string;
  }[];
}

const NavLink: FC<PropsWithChildren<Props>> = ({ children, to, subLinks }) => {
  return (
    <li>
      {!subLinks?.length && (
        <Link
          to={to}
          className="block py-2 pr-4 pl-3 text-gray-700 text-md  rounded md:bg-transparent md:text-black md:p-0 dark:text-white uppercase hover:text-orange-300 transition delay-150 duration-300 hover:bg-gray-100 whitespace-nowrap"
          aria-current="page"
        >
          {children}
        </Link>
      )}

      {!!subLinks?.length && (
        <div className="group inline-block relative z-50">
          <button className="flex py-2 pr-4 pl-3 text-gray-700 text-md  rounded md:bg-transparent md:text-black md:p-0 dark:text-white uppercase hover:text-orange-300 transition delay-150 duration-300 hover:bg-gray-100 whitespace-nowra">
            <span className="mr-1">
              <a href={to}>{children}</a>
            </span>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </button>
          <ul className="absolute hidden text-gray-700 pt-1 group-hover:block">
            {subLinks?.map(({ to, href, text }, i) => {
              return (
                <li className="">
                  <a
                    className=" bg-gray-100 text-gray-700 hover:text-orange-300 transition delay-150 duration-300 hover:bg-gray-100 py-2 px-4 block whitespace-no-wrap"
                    href={to}
                  >
                    {text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
};

export default NavLink;
