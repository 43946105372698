import { Helmet } from "react-helmet-async";

const bgBanner = require("../../assets/images/ProductosBannerBg.png");
const banner = require("../../assets/images/UniRecargaBanner.png");
const ProductStep = require("../../assets/images/ProductosPasos.png");

const contenido = [
  {
    title: "Descripción",
    text: "Es un componente que se instala en los portales Web de clientes que Grupo Único gestiona en su propia plataforma y constituye una operación en modo “ON SITE Exclusivo”, dedicada a la “Adquisición de transacciones electrónicas de compras y pagos varios”.",
  },
  {
    title: "Beneficios",
    text: "Facilita y protege la captura de data sensible y de los identificadores necesarios para gestionar adecuadamente las transacciones electrónicas. Q-VPOS ejecuta las validaciones correspondientes, gestiona lotes de transacciones y totales por comercios a la vez que registra log’s de eventos y operaciones con salvaguarda de las disposiciones PCI y SUDEBAN.",
  },
];

const contenidoRecargasElectronicas = [
  {
    title: "Beneficios",
    text: "Ofrecer una alternativa viable y de rápida implementación para Gestionar las RECARGAS ELECTRÓNICAS y RECAUDACIONES DE PAGOS, que comprende todos los aspectos de la operación:",
    lists: [
      {
        listText: "Procesar Transacciones de Recargas y Recaudaciones ONLINE.",
      },
      {
        listText:
          "Reducir, progresivamente y de acuerdo a los necesidades, el uso de tarjetas de prepago codificadas.",
      },
      {
        listText:
          "Ampliar exponencialmente y de manera inmediata la red de PUNTOS DE VENTAS & RECAUDACIONES.",
      },
      {
        listText:
          "Asegurar la “monetización” de las transacciones de Recargas & Recaudaciones.",
      },
      {
        listText:
          "Proveer eficientes mecanismos de control, conciliación, liquidación y compensación de las ventas y recaudaciones efectuadas en cada Punto de Venta.",
      },
    ],
  },
  {
    title: "Descripción",
    text: "",
    lists: [
      {
        listText:
          "Alternativa viable y de rápida implementación para Gestionar RECARGAS ELECTRÓNICAS y RECAUDACIONES DE PAGOS.",
      },
      {
        listText:
          "Efectividad y Seguridad en las operaciones, evitando pérdidas monetarias en los puntos.",
      },
      {
        listText: "Mejora la calidad del servicio.",
      },
      {
        listText: "Disminuye el uso de tarjetas físicas.",
      },
      {
        listText: "Disminuye los costos operativos.",
      },
    ],
  },
];

const UniRecarga = () => {
  return (
    <div>
      <Helmet>
        <title>UniRecarga -Uniticket</title>
      </Helmet>
      <div
        className="relative overflow-hidden bg-no-repeat bg-cover max-w-auto h-auto mb-20"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${bgBanner})`,
          height: "500px",
        }}
      >
        <div className="flex flex-col items-center justify-items-center mt-16 mb-26">
          <img src={banner} className="max-w-full h-auto text-center" alt="" />
          <div className="flex flex-row mt-8 ">
            <p className="text-orange-500 font-bold text-3xl">Uni</p>
            <p className="text-white font-bold text-3xl">Recarga</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {contenido.map(({ title, text }, i) => {
          return (
            <div className="max-w-auto mx-10 md:mx-40">
              <p className="text-orange-500 text-3xl font-bold my-10">
                {title}
              </p>
              <p className="text-gray-500 text-base text-justify my-10">
                {text}
              </p>
            </div>
          );
        })}

        <h3 className="my-4 mx-4  text-center text-3xl font-bold text-gray-800">
          Recargas Electrónicas
        </h3>

        {contenidoRecargasElectronicas.map(({ title, text, lists }, i) => {
          return (
            <div className="max-w-auto mx-10 md:mx-40">
              <p className="text-orange-500 text-3xl font-bold my-10">
                {title}
              </p>
              <p className="text-gray-500 text-base text-justify my-10">
                {text}
                <div className="flex flex-col justify-center mb-2">
                  {lists.map(({ listText }, i) => {
                    return (
                      <div className="">
                        <ul className="text-gray-500 text-base text-justify my-2 list-disc list-inside">
                          <li className="">{listText}</li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </p>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col items-center justify-items-center m-8 md:my-16 md:mx-40">
        <img
          src={ProductStep}
          className="max-w-full h-auto text-center"
          alt=""
        />
      </div>
    </div>
  );
};

export default UniRecarga;
