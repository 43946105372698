import React from "react";
import { Helmet } from "react-helmet-async";
const bgBanner = require("../assets/images/ProductosBannerBg.png");
const banner = require("../assets/images/ProductosBanner.png");
const TUA = require("../assets/images/ProductosTUA.jpg");
const TMUE = require("../assets/images/ProductosTMUE.jpg");
const TMM = require("../assets/images/ProductosTMM.jpg");

const tarjetas = [
  {
    img: TUA,
    title: "UniTicket Alimentación",
    text: "Tarjeta MAESTRO ideal para optimizar los procesos inherentes a cubrir los gastos asociados a la alimentación del personal para satisfacer las necesidades enmarcados en la Ley que la empresa le otorga a sus trabajadores",
  },
  {
    img: TMUE,
    title: "MAESTRO UniEfectiva",
    text: "Tarjeta MAESTRO solución de pago que ayuda a la empresa a aumentar la eficiencia y productividad del empleado, brindando una mayor satisfacción que se le otorga a sus trabajadores con una amplia gama de beneficios asociados a este tipo de producto por su versatilidad. Genera una relación directamente proporcional entre Motivación y Productividad (Personal y Financiera) tomando en cuenta el talento humano y fomentando el clima laboral deseado",
  },
  {
    img: TMM,
    title: "MAESTRO MULTIBOLSILLO",
    text: "Tarjeta MAESTRO MULTIBOLSILLO desarrollada para cubrir y satisfacer total o parcialmente las necesidades del núcleo familiar directo del beneficiario enmarcados en la Ley, Por medio de esta tarjeta la empresa le otorga a sus trabajadores el beneficio de cancelar el pago de guarderías, le da facilidad para gestionar la compra de juguetes, le brinda el beneficia y apoyo para la compra de útiles y suministros escolares.",
  },
];

const Productos = () => {
  return (
    <div>
      <Helmet>
        <title>Productos -Uniticket</title>
      </Helmet>
      <div
        className="relative overflow-hidden bg-no-repeat bg-cover max-w-auto h-auto"
        style={{
          backgroundPosition: "50%",
          backgroundImage: `url(${bgBanner})`,
          height: "420px",
        }}
      >
        <div className="flex flex-col items-center justify-items-center mt-16 mb-16">
          <img src={banner} className="max-w-full  h-auto text-center" alt="" />
          <div className="flex flex-row mt-8 ">
            <p className="text-orange-500 font-bold text-3xl mr-2">
              Uniticket{" "}
            </p>
            <p className="text-white font-bold text-3xl"> Para Todo</p>
          </div>
        </div>
      </div>

      <div className="my-20">
        <p className="text-orange-500 lg:text-3xl text-3xl md:mx-20 text-center font-bold lg:ml-40 lg:m-10">
          Te invitamos a conocer nuestros productos
        </p>

        {tarjetas.map(({ img, title, text }, i) => {
          return (
            <div className="flex justify-center w-full py-8">
              <div className="flex flex-col basic-1/2 md:flex-row mx-10 lg:mx-60">
                <img
                  className="w-auto h-52 lg:mr-10 animate__animated animate__fadeInLeft animate__delay-0.5s"
                  src={img}
                  alt=""
                />
                <div className="lg:pl-6 mt-8 sm:mt-4 md:mt-0 mx-8  mb-6 lg:mb-0 lg:mt-0 flex flex-col justify-start">
                  <h5 className="text-orange-400 text-2xl font-normal mb-2 ">
                    {title}
                  </h5>
                  <p className="text-gray-500 text-base text-justify">{text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Productos;
