import { Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/NavBar";
import "animate.css";

import { publicDir, routes } from "./routes/routes";

function App({ children }: any) {
  const { home } = routes;

  return (
    <div>
      <Navbar />
      <Routes>
        {publicDir.map((publicRoute) => (
          <Route {...publicRoute} key={publicRoute.path} />
        ))}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
