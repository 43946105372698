import React from "react";
import { classNames, tw } from "../helpers/classNames";
import { FaAngleRight } from "react-icons/fa";
import { routes } from "../routes/routes";
import { Helmet } from "react-helmet-async";

const banner01 = require("../assets/images/Banners-01.png");
const banner02 = require("../assets/images/Banners-02.png");
const banner03 = require("../assets/images/Banners-03.jpg");
const banner04 = require("../assets/images/Banners-04.jpg");
const UD = require("../assets/images/UnicoDigital-img.png");
const CS = require("../assets/images/ConsultaSaldo-img.png");
const PL = require("../assets/images/PagoLiena-img.png");
const UPT = require("../assets/images/cuartilla-caja-UPT.png");
const UR = require("../assets/images/cuartilla-tienda-UR.png");
const UE = require("../assets/images/cuartilla-billete-UE.png");
const UB = require("../assets/images/cuartilla-billetera-UM.png");
const UA = require("../assets/images/cuartilla-carrito-UA.png");
const UF = require("../assets/images/cuartilla-casa-UF.png");
const US = require("../assets/images/cuartilla-salud-US.png");
const URe = require("../assets/images/cuartilla-reembolso-UR.png");

interface Links {
  text: string;
  link?: string;
}
interface SubMenu {
  title: string;
  links: Links[];
  icon: string;
}

const Home = () => {
  const subMenu: SubMenu[] = [
    {
      icon: UD,
      title: "Único Digital",
      links: [
        {
          text: "más información",
          link: "https://www.uniticketdigital.com/UnicoDigital",
        },
      ],
    },
    {
      icon: CS,
      title: "Consulta de Saldo",
      links: [
        {
          text: "más información",
          link: "https://www.uniticketdigital.com/saldo/",
        },
      ],
    },
    {
      icon: PL,
      title: "Soporte en Línea",
      links: [
        {
          text: "más información",
          link: "https://www.uniticketdigital.com/pago-en-linea/",
        },
      ],
    },
  ];

  const cuartillas = [
    {
      icon: UPT,
      text: "Simplifica con una tarjeta la entrega de beneficios a tus empleados.",
      linkText: "Uniticket para todo",
      link: routes.productos,
    },
    {
      icon: UR,
      text: "Instalamos en tu web el sistema de recargas electrónicas y recaudaciones de pago.",
      linkText: "UniRecarga",
      link: routes.productosUniRecarga,
    },
    {
      icon: UE,
      text: "Destácate con la tarjeta prepagada que impulsa la motivación y la productividad.",
      linkText: "UniEfectiva",
      link: routes.productosUniEfectiva,
    },
    {
      icon: UB,
      text: "Para los pequeños gastos de todos los días, usa la tarjeta Contac Less.",
      linkText: "UniMonedero",
      link: routes.productosUniMonedero,
    },
    {
      icon: UA,
      text: "Ofrece a tus trabajadores la tranquilidad de gozar de un beneficio de fácil uso.",
      linkText: "Uniticket Alimentación",
      link: routes.productosUniticketAlimentacion,
    },
    {
      icon: UF,
      text: "Tarjeta para cubrir gastos fundamentales del núcleo familiar.",
      linkText: "UniFamilia",
      link: routes.productosUniFamilia,
    },
    {
      icon: US,
      text: "Incentivos para el cuidado de la salud gastos médicos y odontológicos.",
      linkText: "UniSalud",
      link: routes.productosUniSalud,
    },
    {
      icon: URe,
      text: "Cuentas claras gracias a una tarjeta que reembolsa los gastos aprobados por la empresa.",
      linkText: "UniRembolso",
      link: routes.productosUniReembolsos,
    },
  ];

  const carrusel = [
    {
      text: "Suma agilidad y mayor eficiencia con las mejores opciones de medios de pago",
      img: banner02,
      textBtn: "Descubre más",
      link: routes.productosUniSalud,
    },
    {
      text: "Sin lo engorroso del papel, podrás pagar en un santiamén.",
      img: banner01,
      textBtn: "Haz click",
      link: routes.productosUniticketAlimentacion,
    },
    {
      text: "El mejor incentivo: cubrir necesidades de tu familia.",
      img: banner03,
      textBtn: "Enterate cómo",
      link: routes.productosUniSalud,
    },
    {
      img: banner04,
      textBtn: "Descubre más",
      link: routes.productosUniticketAlimentacion,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Inicio -Uniticket</title>
      </Helmet>
      <div
        id="carouselExampleIndicators"
        className="carousel slide relative sm:w-full md:w-full"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4 z-20">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
        </div>
        <div className="carousel-inner relative w-full overflow-hidden ">
          {carrusel.map(({ text, img, textBtn, link }, i) => {
            return (
              <div
                className={`carousel-item ${
                  i === 0 ? "active" : ""
                } float-left w-full relative`}
              >
                <div
                  className=" bg-cover h-72 lg:h-[500px]"
                  style={{
                    backgroundPosition: "50%",
                    backgroundImage: `url(${img})`,
                  }}
                >
                  <div className="w-full h-full absolute z-10 bg-black bg-opacity-50 flex flex-col justify-center px-8 lg:px-56 ">
                    <p className="text-xl lg:text-5xl font-light text-white w-2/3 mb-6 animate__animated animate__fadeInUp animate__delay-0.5s">
                      {text}
                    </p>
                    <div className="animate__animated animate__fadeInUp animate__delay-0.5s">
                      <a href={link}>
                        <button className="flex items-center text-white  border-2 px-4 py-1 lg:py-3 text-base lg:text-xl hover:border-transparent hover:bg-white hover:bg-opacity-10 hover:text-orange-500 transition-all">
                          {textBtn} <FaAngleRight className="ml-4" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="hidden carousel-control-prev absolute top-0 bottom-0 md:flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0 z-20"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="hidden carousel-control-next absolute top-0 bottom-0 md:flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0 z-20"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="flex flex-col sm:flex-row md:flex-row bg-gradient-to-r from-yellow-400 to-yellow-500  ">
        {subMenu.map(({ links, icon, title }, i) => {
          return (
            <div className="basis-1/3 border-x border-y border-white">
              <div className="flex flex-row px-6 pt-10 pb-2 ml-4 justify-between">
                <h5 className="text-gray-100 text-3xl leading-tight font-medium font-sans ">
                  {title}
                </h5>
                <img src={icon} className="self-center w-auto h-8  mr-4" />
              </div>
              {links.map(({ text, link }, i) => (
                <a href={link}>
                  <p className="text-gray-200 text-base px-6 pb-10 ml-4">
                    {text}
                  </p>
                </a>
              ))}
            </div>
          );
        })}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 bg-gray-300 ">
        {cuartillas.map(({ linkText, link, text, icon }, i) => {
          const classes = classNames(tw`flex flex-col p-6 items-center`, {
            [tw`bg-white`]: i < 4 ? i % 2 === 0 : i % 2 !== 0,
            [tw`bg-gray-300`]: i < 4 ? i % 2 !== 0 : i % 2 === 0,
          });

          return (
            <a href={link} className={classes}>
              <img src={icon} className="w-20 h-20 my-5 mx-auto" />
              <p className="text-orange-400 text-center px-6 pt-4 font-semibold ">
                {linkText}
              </p>
              <p className="text-gray-600 text-base text-center">{text}</p>
            </a>
          );
        })}
      </div>
    </>
  );
};

export default Home;
